import React from 'react';
// import Me from './me/Me';

function About() {
  return (
    <section className="bg-white shadow rounded d-flex align-items-center justify-content-center">
      {/* <Me></Me> */}
      <h2 className='text-center'>商务合作请致电：15342349100(李先生)</h2>
    </section>
  );
}

export default About;
